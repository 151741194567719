.title {
  color: #fafafa;
  font-size: 20px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.2px;
  padding-bottom: 15px;
}

.boxWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.singleAction {
  border-radius: 4px;
  border-bottom: 2px solid #FCD435;
  background: #2a2a2a;
  padding: 12px;
  padding-bottom: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading,
.balance {
  color: #fafafa;
  text-align: center;
  font-size: 22px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.heading {
  width: 100%;
  padding-top: 12px;
  padding: 18px 0;
  border-bottom: 1px solid rgba(57, 57, 60, 0.698);
}

.balance {
  padding-top: 12px;
}

.inUsd {
  color: rgba(250, 250, 250, 0.3);
  text-align: center;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding-top: 5px;
  padding-bottom: 18px;
}

.button {
  text-align: center;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 10px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #000;

  background: #FCD435;
}

@media only screen and (max-width: 1399px) {
  .boxWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1199px) {
  .boxWrapper {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .boxWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .boxWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 520px) {
  .boxWrapper {
    grid-template-columns: 1fr;
  }
}