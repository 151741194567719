.chart {
  width: 100%;
  min-height: 150px;
}
.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 15px;
  gap: 10px;
  background: #0d0d0d;
  border-radius: 10px 0px 0px 10px;
}
.requestContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.iconContainer {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(13, 59, 250, 0.15);
  border-radius: 12px;
}
.icon {
  font-size: 18px;
  color: #1146e7;
}
.text {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  color: #9c9ca4;
}
.remainingRequest {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 190%;

  color: #ffffff;
}
.exploreButton {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  background: #1146e7;
  border-radius: 8.53933px;

  padding: 12px 45px;
  cursor: pointer;
  border: none;
  display: block;
  margin-left: auto;
}
.chartContainer {
  background: #0d0d0d;
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px 0px 10px 10px;
}
.chartWrapper {
  width: 100%;
}

.headingAndTick {
  display: flex;
  align-items: center;
  gap: 10px;
}
.usageHeading {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 26px;
}
.apexHeading {
  display: flex;
  justify-content: space-between;

  margin: auto;
  padding: 0 15px;
  padding-top: 20px;
}
.track {
  color: #1146e7;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 12px;
}
.tickContainer {
  width: 15px;
  height: 15px;
  background: #1146e7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.tick {
  color: #fff;
  font-size: 15px;
}
@media only screen and (max-width: 520px) {
  .exploreButton {
    padding: 8px 30px;
  }
}

@media only screen and (max-width: 380px) {
  .apexHeading {
    padding: 0 10px;
  }
  .chart {
    margin-left: -2%;
  }
}
