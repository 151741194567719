.button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding-bottom: 8px;
}
.icon {
  font-size: 18px;
  color: #a1a1a1;
}
.text {
  color: #a1a1a1;
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
