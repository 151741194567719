.investmentAccount {
  background: #2a2a2a url(../../../images/calculator.png);
  background-size: 130px;
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 3px 0px 0px 3px;
  border-left: 1px solid #FCD435;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  padding: 22px 18px;
  max-width: 434px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
}

.title {
  color: #fafafa;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.amountContainer {
  display: flex;
  align-items: center;
  gap: 45px;
}

.availableAmount {
  color: #FCD435;
  font-size: 24px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.lockedAmount {
  color: #fafafa;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.currency {
  color: #fafafa;
}

.text {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.button {
  color: #2a2a2a;
  background: #FCD435;
  text-align: center;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .investmentAccount {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .investmentAccount {
    max-width: 434px;
  }
}

@media only screen and (max-width: 991px) {
  .investmentAccount {
    max-width: 100%;
    background-position: 90%;
  }
}